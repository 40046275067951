
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

html {
  scroll-behavior: smooth;
  --deafultGoldColor: #F6A821;
  --deafultDarkGoldColor: #d98a01;
  --deafultLightGoldColor: #C8890133;

  --defaultDarkBlack: #24262D;
  --defaultMediumBlack: #2A2D35;
  --defaultLightBlack: #3C3E48;

  --defaultWhite: white;
  --defaultBlack:#6d757d;
  --defaultFamily:'Poppins';
    font-family: 'Poppins', sans-serif!important
}

body{
  font-family: 'Poppins', sans-serif!important;
  background:var(--defaultMediumBlack)
}
/*********Side bar *****************/
.sidebar-top-header{
  /* background: var(--deafultGoldColor); */
  height: 76px;
  margin-top:20px;
}
.bg {
    /* background: url("../images//banner.png") no-repeat center/cover; */
    width: 100%;
    height: 100vh;
  }

  .Logo {
    height: 100px;
    margin-bottom: 30px;
}

.Logo img{
  height: 100%;
  /* width: 100%; */
}
 .login_container {
  background-color: var(--defaultLightBlack);
 }

.account_form{
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  padding: 1em 5rem;
}

.button_Commomn button{
  color: white;
  border: none;
  background: var(--deafultGoldColor);
  border-radius: 8px;

    padding: 14px;
    font-size: 0.9rem;
    font-family: var(--defaultRegularFamily);
    font-weight: 600;
}
.form-control:focus{
  background-color: var(--defaultLightBlack)!important;
  color:white;
}
.button_success{
  color: white;
  border: none;
  background: var(--deafultDarkGoldColor);
  border-radius: 8px;
  padding: 14px;
  font-weight: 400;
}
.button_cancel{
  color: var(--deafultGoldColor);
    border: 1px solid var(--deafultDarkGoldColor);
    /* background: var(--defaultMediumBlack); */
    border-radius: 8px;
    padding: 14px;
    /* font-size: 0.8rem; */
    font-family: var(--defaultRegularFamily);
    font-weight: 400;
    background: transparent;
  /* color: var(--deafultGoldColor);
  border: 1px solid var(--deafultLightGoldColor);
  background: var(--defaultwhitecolor);
  border-radius: 8px;
  padding: 14px;
  /* font-size: 0.8rem; */
  /* font-family: var(--defaultRegularFamily);
  font-weight: 400;  */
}

.button_Commomn{
  display: flex;
    justify-content: center;
}

.form_align{
  display: flex;
  align-items: center;
  justify-content: center;
}

.title_tag{
  font-family: var(--defaultFamily);
  /* font-size: 1.2rem; */
  font-weight: 700;
  color:var(--defaultWhite)
}
.titleContent{
  font-family: var(--defaultRegularFamily);
  font-weight: 400;
  font-size: 0.8rem;
  text-align: center;
}

.inputs_placeholder ::placeholder {
  font-size: 0.8rem;
  opacity: 1;
  color: #828282;
}
.search_bar ::placeholder {
 color: var(--defaultBlack)!important;
}
.inputs_placeholder input{
  background-color: var(--defaultLightBlack);
  border: none;
  padding-left: 40px;
}
.inputs_placeholder1 ::placeholder {
  font-size: 0.8rem;
  opacity: 1;
  color: #828282;
}
.inputs_placeholder1 input{
  background-color:  var(--defaultLightBlack);;
  border: none;
  /* padding-left: 30px; */
}
.toggle_parent {
  position: relative;
  display: flex;
  justify-content: end;
  align-items: center;
}
.toggle_child {
  position: absolute;
  color: #c7c3c3;
  font-weight: bold;
  font-size: 0.9rem;
  left: 10px;
  font-size: 18px;
  /* top: 9px; */

}

.eye_child{
  position: absolute;
  color: #c7c3c3;
  font-weight: bold;
  font-size: 0.9rem;
  right: 10px;
  font-size: 18px;
}
.LinkRouter{
  color: var(--defaultBlack)!important;
  text-decoration: none;
  color: inherit;

  
}
.LinkRouter:hover{
  color: var(--defaultBlack)
} 
.titleContent.color{
  color: #828282;
}
.social_img img{
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.social_img {
  height: 28px;
}

.bg_social {
  background-color: #F1F1F1;
  width: 3.5rem;
  height: 48px;
  padding: 8px;
  border-radius: 8px;
  margin: 5px;
  cursor: pointer;

}
.social{
  display: flex;
  justify-content: center;
}
.ant-layout {
  height: 100vh;
}
/* .ant-layout-sider{
  background-color: white !important;
} */
.headerbar {
  background-color: var(--deafultDarkGoldColor) !important;
  box-shadow: 0px 4px 4px #0000000A;
  height:76px
}
.sidebar {
  background-color:var(--defaultDarkBlack)!important;
  border:none!important;
  height:100vh
}
.menu_list {
  font-weight: 600; 
  font-size: '13px'; 
  background-color:var(--defaultDarkBlack)!important;
}
.ant-layout-sider {
  flex: none !important;
  max-width: 274px !important;
  min-width: 274px !important;
  width: 260px !important;
  
}
.setting_img img{
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.setting_img{
  height: 20px;
}
.header_profile{
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
}
.avtar img{
  /* width: 100%; */
  height: 100%;
  border-radius: 50%;
}
.avtar{
  height: 40px;
  cursor: pointer;
}
.wrapp{
  display: flex;
}
.ant-menu-title-content{
  font-family: var(--defaultFamily);
  color: #a72525;
  font-weight: 400;
}
.active{
  color: var(--deafultGoldColor)!important;
  font-family: var(--defaultFamily);
  font-weight: bold
}
.unactive{
  color: var(--defaultWhite)!important;
  font-family: var(--defaultFamily);
  font-weight: bold
}
.card_home{
  display: flex;
  align-items: center;
}
.card_main{
  background-color: var(--defaultLightBlack);
  color: wheat;
  padding: 19px;
  border-radius: 8px;
}
.card_Img img{
height: 100%;
/* width: 100%; */
object-fit: contain;
}
.card_Img{
  height: 60px;
}
.card_home span{
  font-size: 1.0rem;
  font-family:var(--defaultFamily);
  font-weight: 600;
  
}
.counts{
  font-family: var(--defaultFamily);
  /* font-weight: 700; */
  
  /* margin-left: 30px; */

}
.ant-layout-content {
  background-color: var(--defaultMediumBlack);
}


.anticon.anticon-down{
  display: none !important;
}
  
/* .ant-avatar-circle {
  height: 26px !important;
} */
.ant-menu-item{
  color:red!important;
}
.ant-menu-item:hover{
  color:red;
}
.ant-menu-submenu-title{
  color:red!important
}

.toogle_hide{
  visibility: hidden;
}

.search_bar {
  height:40px;
  border-radius:8px;
  border:1px solid var(--deafultLightGoldColor);
  background: var(--defaultLightBlack);
  color: var(--defaultBlack)!important;
}
.search_bar input {
  background: transparent;
  color: var(--defaultWhite)!important;
}

.ant-menu-inline{
     border-inline-end: 0px solid rgba(5, 5, 5, 0.06)!important; 
}

/* side bar  */
.ant-menu-item-selected {
  background-color:  var(--defaultMediumBlack)!important;
   border-left:3px solid var(--deafultGoldColor);
   border-right:3px solid var(--deafultGoldColor);
   border-radius: 0px!important;
  /* background-color: red!important; */
  color:red!important;
}
.ant-menu-item-selected span {
  /* color:white!important; */
  color:var(--deafultGoldColor)!important;
  font-weight:600;
}
.ant-dropdown{
  background-color: var(--defaultMediumBlack)!important;
  border:1px solid var(--deafultGoldColor)
}
.ant-dropdown-menu{
  background-color: var(--defaultMediumBlack)!important;
 
}
.ant-dropdown-menu-item{
  background-color: var(--defaultMediumBlack)!important;

}

.ant-menu-item:hover {
  color: white !important;
}

.ant-table table th{ font-size: 13px; }
.ant-table {background-color: #a72525; }

.sideContent {
  margin: 25px 40px;
  padding: 24;
  background-color: var(--defaultMediumBlack);
  /* min-height: 280;  */

}

.breadcrumb{
  margin: 16px 12px;
}

#inner_form input:focus, input.form-control:focus {
  outline:none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  /* background:#f0f0f0; */
  /* border-bottom:0.1rem solid var(--deafultLightGoldColor)!important; */
  -webkit-box-shadow: 0 1px 0 0 var(--deafultLightGoldColor);
  /* box-shadow: 0 1px 0 0 var(--deafultLightGoldColor); */
}


#inner_form .form-control {
  background:transparent;
  border-radius: 10px;
  font-family: var(--defaultFamily);
  font-size:14px;
  height:46px!important;
  color:white!important;
}

label{
  /* font-weight: bold; */
  color:gray;
  font-size: 13px;
  margin-bottom:8px
}

.form-check-input {
  width: 1.25em;
  height: 1.25em;
  margin-top: 0.3em;
  margin-right: 0.5em;
  border: 2px solid #ccc;
  border-radius: 0.25em;
}

.form-check-input:checked {
  background-color:var(--deafultGoldColor);
  border-color: var(--deafultGoldColor);
}
.form-switch .form-check-input{
  width:3em!important
}

.form-check-input{
  height: 1.75em!important;
}

.form-check-label {
  margin-left: 0.5em;
  font-size: 1.2em;
  font-weight: bold;
  color: var(--defaultWhite);
}

/* This css is for normalizing styles. You can skip this. */
*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}



.new {
  padding: 50px;
}

.form-group-checkbox {
  display: block;
  /* margin-bottom: 15px; */
}

.form-group-checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group-checkbox label {
  position: relative;
  cursor: pointer;
}

.form-group-checkbox label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid var(--deafultLightGoldColor);
  box-shadow: 0 1px 2px rgb(0 0 0 / 5%), inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 0px;
}

.form-group-checkbox input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 8px;
  width: 6px;
  height: 11px;
  border: solid var(--deafultLightGoldColor);;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

#privilege_heading{
  font-weight: bolder;
  color: var(--deafultGoldColor)
}
 .privilege_options span{
   font-weight: bolder;
   color: black;
 }

 .select-dropdown,
.select-dropdown * {
	margin: 0;
	padding: 0;
	position: relative;
	box-sizing: border-box;
}
.select-dropdown {
	position: relative;
	background-color: transparent;
	border-radius: 4px;
}
.select-dropdown select {
  color:black;
	font-weight: normal;
	max-width: 100%;
	padding:10px 25px;
  border: none;
  border-radius: 10px;
	/* background-color: #FFCECE; */
		-webkit-appearance: none;
		-moz-appearance: none;
	appearance: none;
}
.select-dropdown select:active, .select-dropdown select:focus {
	outline: none;
  box-shadow: none;

}
.select-dropdown:after {
	content: "";
  position: absolute;
	top: 50%;
	right: 12px;
	width: 0;
	height: 0;
	margin-top: -2px;
	border-top: 5px solid #0c0b0b;
	border-right: 5px solid transparent;
	border-left: 5px solid transparent;
}


.text_dark_bold{
  font-weight: 600;
  color:var(--defaultBlack)
}
.ant-pagination-item-active{
  background-color: var(--defaultDarkBlack)!important;
  border-color:var(--deafultGoldColor)!important;
  color: white!important;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.spinner {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-left-color: #fff;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spinner 0.8s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}


.form-control {
  border: 1px solid var(--deafultLightGoldColor)!important;
  color: var(--defaultBlack)!important;
  /* border-bottom: 2px solid #093EAB !important; */
  /* border-radius: 0rem; */
}
.claim_status {
    text-transform: capitalize;
    cursor: pointer;
    padding: 11px 20px;
    border-radius: 0.5rem;
    /* font-weight:600 */
}
li{
  color: var(--defaultWhite) !important
}
li span{
  color: var(--defaultWhite) !important;
  /* font-weight: 600 !important; */
}
i{
  color: var(--defaultBlack)!important
}

.ant-modal .ant-modal-content {
  background-color: var(--defaultDarkBlack);
  box-shadow: 7px 3px 7px;
}
.anticon-close{
  color:white
}
.text-primary{
  color:var(--deafultGoldColor)!important;
}
.avatar_header{
  background-color: var(--defaultDarkBlack)!important;
  font-weight: 600;
}
.header_email{
  font-weight: 600;
  color:#2b2d35
}

/* user detail */
.user_detail_list{
  background: whitesmoke;
  padding: 13px 11px;
  border: 1px solid var(--defaultbluecolor);
  display: flex;
  border-radius: 8px;
}

.footerdash {
  background-color: var(--deafultGoldColor);
  color: var(--defaultwhitecolor);
  align-items: center;
  padding: 1px;
  display: flex;
  justify-content: center;
}
.footlogo img {
  height: 100%;
  object-fit: contain;
  width: auto;
}

.footlogo {
  height: 30px;
}

/*Admin profile page*/
.profile_container{
  background-color: var(--defaultLightBlack)!important;
}

/*Table div*/
.table-container{
  padding:10px
}
.ant-table-tbody{
  background-color: var(--defaultLightBlack)!important;
  color:white
}
.ant-table-tbody :hover{
  color:black
}
.ant-select-selector{
  background-color: var(--defaultDarkBlack)!important
}
.ant-table-expanded-row-fixed{
  background-color:var(--defaultLightBlack)
}
/*rightpanel*/

.rightPanel{
    margin-left: 250px!important;
}

@media screen and (max-width: 767px) {
  .rightPanel{
      margin-left: 75px!important;
  }
}


/*****JODIT EDITOR******/
.jodit-toolbar-editor-collection {
  background-color: var(--defaultLightBlack)!important;
}
.jodit_theme_dark.jodit-container{
  border-color: var(--defaultLightBlack)!important;
  background-color:var(--defaultLightBlack)!important;
}
.jodit-workplace{
  background-color: var(--defaultLightBlack)!important;
}
.jodit-wysiwyg{
  background-color: var(--defaultLightBlack)!important;
  color:white!important
}

/*************Buttons*********/
.button_dark{
  background-color: var(--defaultLightBlack);
  color:var(var(--defaultBlack));
}

/*******Detail page tabs*********/
.detail_tabs{
  color:var(--defaultWhite)!important;
  background: var(--defaultLightBlack)!important;
  /* padding: 25px!important  */
}
.ant-tabs-nav-wrap{
  background: var(--defaultMediumBlack)!important;
    border: 1px solid var(--defaultLightBlack)!important;
}

.ant-tabs-tab{
  padding: 18px 20px!important;
  color: white!important;
}
.ant-tabs-tab-active{
  background: var(--deafultDarkGoldColor)!important;
  color: white!important;
  border-bottom-color:red!important;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color:var(--defaultWhite)!important;
}
.tab_detail_box{
  cursor: pointer;
  border: 1px solid var(--defaultBlack)!important;
  padding: 14px;
  border-radius: 5px;
  background-color: var(--defaultMediumBlack);
}

.tab_detail_box .list_content{
  font-size:14px;
}
.tab_detail_box .list_content_span{
  font-weight:bold;
}

.ant-tabs-content-holder{
  min-height:400px
}

.collection_image {
  height:250px!important;
  object-fit:contain;
}
/************Terms and conditions*************/
.view-header {
  margin: 50px 0px;
  min-height: 50px;
  /* padding: 0 15px; */
  color:wheat;
}

.view-header .header-icon {
  font-size: 60px;
  color: #f6a821;
  width: 68px;
  float: left;
  margin-top: -8px;
  line-height: 0;
}

.view-header .header-title {
    margin-left: 22px;
}

.ant-tabs-content-holder{
  min-height: 60vh!important;
}

.collection_detail{
  background: var(--defaultLightBlack);
  color: white;
  padding: 30px;
}

.ant-modal-mask {
  background-color: rgb(0 0 0 / 84%)!important
}